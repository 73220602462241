exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-templates-announcement-js": () => import("./../../../src/templates/announcement.js" /* webpackChunkName: "component---src-templates-announcement-js" */),
  "component---src-templates-contact-js": () => import("./../../../src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-hearing-officer-js": () => import("./../../../src/templates/hearingOfficer.js" /* webpackChunkName: "component---src-templates-hearing-officer-js" */),
  "component---src-templates-judge-js": () => import("./../../../src/templates/judge.js" /* webpackChunkName: "component---src-templates-judge-js" */),
  "component---src-templates-location-js": () => import("./../../../src/templates/location.js" /* webpackChunkName: "component---src-templates-location-js" */),
  "component---src-templates-magistrate-js": () => import("./../../../src/templates/magistrate.js" /* webpackChunkName: "component---src-templates-magistrate-js" */),
  "component---src-templates-page-definition-js": () => import("./../../../src/templates/pageDefinition.js" /* webpackChunkName: "component---src-templates-page-definition-js" */),
  "component---src-templates-record-js": () => import("./../../../src/templates/record.js" /* webpackChunkName: "component---src-templates-record-js" */),
  "component---src-templates-record-type-js": () => import("./../../../src/templates/recordType.js" /* webpackChunkName: "component---src-templates-record-type-js" */)
}

